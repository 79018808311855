import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";
import { useStore } from "../../store/globalstate";
import { SpinnerBig } from "../spinner/spinner";

import "./receiveMsgPage.scss"

export const ReceiveMsgPage = (props: RouteComponentProps) => {
  const receiveMsg = useStore((s) => s.receiveMsg);
  useEffect(() => {
    // @ts-ignore
    window.onmessage = ((e: MessageEvent) => {
      if (e && e.data && e.data.type === 'session') {
        receiveMsg(e.data.payload)
      }
    })
  }, []);

  return (
    <div className="loading-container">
      <div>
        <SpinnerBig />
      </div>
    </div>
    );
};