import "./spinner.scss";

export const Spinner = () => {
  return (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export const SpinnerBig = () => {
  return (
    <div className="lds-ring-big">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
