import { useStore } from "../store/globalstate";
import { TSelection } from "../store/types";

export type Source = "desktop" | "old-mobile" | ""; // client

export type IPostMessage = {
  otp: string;
  novaToken?: string;
  selections: IPostMessageSelection[];
  stake: number;
  source?: Source;
};

export type IPostMessageSelection = {
  selection: string;
  event: string;
  market: string;
  sign: string;
  odd: number | string;
};

export const createPostMessage = (
  betslip: TSelection[],
  stake: number
): IPostMessage => ({
  selections: betslip.map((s) => createSelection(s)),
  otp: useStore.getState().session,
  stake,
});

const createSelection = ({
  id,
  code,
  team1,
  team2,
  name,
  outcome,
  value,
}: TSelection): IPostMessageSelection => ({
  selection: id + "$" + code,
  event: team1 + " - " + team2,
  market: name,
  sign: outcome || "",
  odd: value,
});
