import axios from "axios";

export const httpClient = axios.create({
  headers: {
    accept: "application/json",
    "content-Type": "application/json",
  },
  baseURL: "https://api.dummysport.cert.novafutur.com/acct",
  // timeout: 5000,
  // timeoutErrorMessage: "Front-end Timed-out",
});

// httpClient.interceptors.response.use(
//   (response: AxiosResponse) => response,
//   (error: AxiosError) => {
//     console.error(error);
//   }
// );

//// API CALLS
