import { useStore } from "../../store/globalstate";
import { removeUnderscore } from "../../lib/utils";
import { MatchDetails } from "../matchDetails/matchDetails";
import { OddButton } from "../button/button";
import { TMatch } from "../../api/types";
import { SpinnerBig } from "../spinner/spinner";
import "./sportsBook.scss";

export const Sportsbook = () => {
  const detailView = useStore((state) => state.detailView);

  return (
    <div className="sportsbook">
      {!detailView ? <Matches /> : <MatchDetails />}
    </div>
  );
};

const Matches = () => {
  const sportsbook = useStore((state) => state.sportsbook);
  const sportsStatus = useStore((state) => state.sportsbookStatus);

  // if (sportsStatus === "loading") {
  //   // return <div>Loading ...</div>;
  //   return <Spinner />;
  // }

  if (sportsStatus === "error") {
    return <div> an error occurred ...</div>;
  }

  return (
    <>
      <div className="sportsbook__heading">Sportsbook</div>

      {sportsStatus === "loading" ? (
        <div className="sportsbook__spinner">
          <SpinnerBig />
        </div>
      ) : (
        <div className="scroll-wrapper">
          <ul className="sportsbook__list">
            {sportsbook.map((match) => (
              <li className="sportsbook__item" key={match.id}>
                <MatchInfo match={match} />
                <MatchOptions match={match} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

const MatchInfo: React.FC<{ match: TMatch }> = ({ match }) => {
  const viewMatchDetails = useStore((state) => state.viewMatchDetails);
  return (
    <div className="matchInfo" onClick={() => viewMatchDetails(match)}>
      <div className="matchInfo-market">
        {removeUnderscore(match.odds["1"].name)}
      </div>
      <span className="matchInfo-teams">
        {match.team1} - {match.team2}
      </span>
    </div>
  );
};

const MatchOptions: React.FC<{ match: TMatch }> = ({ match }) => {
  const market = "1";
  const options = match.odds[market].options;
  return (
    <div className="u-flex">
      {Object.keys(options).map((outcome, i) => (
        <div className="odds" key={outcome + i}>
          <div className="outcomes">{outcome}</div>
          <OddButton
            option={options[outcome]}
            outcome={outcome}
            market={market}
            match={match}
          />
        </div>
      ))}
    </div>
  );
};
