import { GlobalState, useStore } from "../../store/globalstate";
import { removeUnderscore, totalOdds, totalWin } from "../../lib/utils";
import { TSelection } from "../../store/types";
import shallow from "zustand/shallow";
import { SimulateLogo } from "../svg";
import "./betslip.scss";

export const Betslip = () => {
  const betslip = useStore((state) => state.betslip);

  if (betslip.length === 0) {
    return (
      <div className="betslip">
        <div className="betslip__heading">Betslip</div>
        <div className="no-data">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="big-icon"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
            </svg>
          </div>
          <p>You have not added any selections yet...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="betslip">
      <div className="betslip__heading">Betslip</div>
      <BetslipList />
      <div className="betslip__footer">
        <BetslipInfo />
        <div className="betslip__input">
          <div>
            <InputStake />
            <EmptyBetslipButton />
          </div>
        </div>
      </div>
    </div>
  );
};

const BetslipList = () => {
  const betslip = useStore((state) => state.betslip);

  return (
    <div className="scroll-wrapper">
      <ul className="betslip__list">
        {betslip.map((selection, i) => (
          <BetslipItem key={selection.id / i} selection={selection} />
        ))}
      </ul>
    </div>
  );
};

export const BetslipItem: React.FC<{ selection: TSelection }> = ({
  selection,
}) => {
  const deleteBetslipItem = useStore((state) => state.deleteBetslipItem);
  return (
    <li className="betslip__item">
      <div>
        <div className="selection-market">
          {removeUnderscore(selection.name)}
        </div>

        <span className="selection-teams">{selection.team1} - </span>
        <span className="selection-teams">{selection.team2}</span>
      </div>
      <div>
        <span className="selection-outcome">{selection.outcome}</span>
        <span className="odd">{selection.value}</span>
        <button
          className="remove-btn"
          onClick={() => deleteBetslipItem(selection)}
        >
          X
        </button>
      </div>
    </li>
  );
};

const betSlipInfoSelector = (state: GlobalState) => {
  const tOdds = totalOdds(state.betslip);
  const pWin = totalWin(tOdds, state.stake);
  const length = state.betslip.length;
  return {
    selectionLength: length,
    totalOdds: tOdds,
    potentialWin: pWin,
  };
};

const BetslipInfo = () => {
  const toggleSimulate = useStore((s) => s.toggleSimulate);

  const { selectionLength, potentialWin, totalOdds } =
    useStore(betSlipInfoSelector);

  return (
    <>
      <button className="simulate__button" onClick={() => toggleSimulate()}>
        <SimulateLogo
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#334155"
        />
        Simulate
      </button>
      <div className="betslip__info">
        <div className="selection">
          <span className="title">Selections</span>
          <br />
          <span className="value">{selectionLength}</span>
        </div>
        <div className="totalOdds">
          <span className="title">Total Odds</span>
          <br />
          <span className="value">
            {Number(totalOdds.toFixed(2)).toLocaleString()}
          </span>
        </div>
        <div className="win">
          <span className="title">Potential Win</span>
          <br />
          <span className="value">${potentialWin.toLocaleString()}</span>
        </div>
      </div>
    </>
  );
};

const EmptyBetslipButton = () => {
  const emptyBetslip = useStore((state) => state.emptyBetslip);
  return (
    <button className="betslip__button" onClick={emptyBetslip}>
      Clear Betslip
    </button>
  );
};

const InputStake = () => {
  const stake = useStore((state) => state.stake, shallow);
  const updateStake = useStore((state) => state.updateStake);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateStake(parseInt(e.target.value) || 0);
  };
  return (
    <input
      className="betslip__inputStake"
      type="number"
      name="stake"
      value={stake ? stake : ""}
      onChange={handleChange}
    />
  );
};
