import { RouteComponentProps } from "@reach/router";
import { useEffect } from "react";
import { useStore } from "../../store/globalstate";
import { TTransactionResponse } from "../../api/types";
import "./transactionsView.scss";
import { SpinnerBig } from "../spinner/spinner";

export const TransactionsView = (props: RouteComponentProps) => {
  const transactionStatus = useStore((state) => state.transactionStatus);
  return (
    <div className="transaction">
      <div className="transaction__heading">Transactions</div>
      {transactionStatus === "error" && <div>...An error Occured</div>}
      <Pagination />
    </div>
  );
};

const Pagination: React.FC = () => {
  const page = useStore((state) => state.page);
  const cursor = useStore((state) => state.cursor);
  const transactionStatus = useStore((state) => state.transactionStatus);
  const previousList = useStore((state) => state.getPreviousList);
  const nextList = useStore((state) => state.getNextList);
  const transaction = useStore((state) => state.fetchTransactionsList);

  useEffect(() => {
    transaction();
  }, [transaction]);

  if (transactionStatus === "loading") {
    return (
      <div className="transaction__spinner">
        <SpinnerBig />
      </div>
    );
  }

  return (
    <>
      <Transactions />
      <div className="transaction__pagination">
        <input
          className="button"
          type="button"
          value="previous"
          onClick={() => previousList()}
          disabled={page === 0}
        />
        <span className="page-number"> Page: {page + 1} </span>
        <input
          className="button"
          type="button"
          value="next"
          disabled={cursor === null}
          onClick={() => nextList()}
        />
      </div>
    </>
  );
};

const Transactions = () => {
  // const transaction = useStore((state) => state.fetchTransactionsList);
  const transactionList = useStore((state) => state.list);

  // useEffect(() => {
  //   transaction();
  // }, [transaction]);

  return (
    <div className="scroll-wrapper">
      <ul className="transaction__list">
        {transactionList.map((t: TTransactionResponse) => {
          return (
            <li key={t.id} className="transaction__item">
              <span className="title">User ID:</span>
              <span className="value">{t.acctid}</span>
              <br />
              <span className="title">Bet ID:</span>
              <span className="value">{t.bet_reference}</span>
              <br />
              <span className="title">Transaction ID:</span>
              <span className="value">{t.reference}</span>
              <br />
              <span className="title">Time:</span>
              <span className="value">{t.time}</span>
              <br />
              <span className="title">Balance:</span>
              <span className="value">{t.balance}</span>
              <br />
              <span className="title">Value:</span>
              <span className="value">{t.value}</span>
              <br />
              <span className="title">Action:</span>
              <span className="value">{t.action}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
