import React from "react";
import { Redirect, RouteComponentProps } from "@reach/router";
import { useStore } from "../../store/globalstate";
import { Header } from "../header/header";

import "./homePage.scss";

interface LayoutProps extends RouteComponentProps {}
export const HomePage: React.FC<LayoutProps> = ({ children }) => {
  const isAuthenticated = useStore((s) => s.session);
  return (
    <>
      {isAuthenticated ? (
        <div style={{ height: "100%" }}>
          <Header />

          <div className="page-wrapper">{children}</div>
        </div>
      ) : (
        <Redirect to="/" noThrow />
      )}
    </>
  );
};
