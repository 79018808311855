import create from "zustand";
import { navigate } from "@reach/router";
import { devtools } from "zustand/middleware";
import { httpClient } from "../api/httpClient";
import {
  TBalanceResponse,
  TLoginRequest,
  TLoginResponse,
  TTransactionResponse,
} from "../api/types";
import { TAPIStatus, TSelection } from "./types";
import { SportsBook, TMatch } from "../api/types";
import mockdata from "../__mocks__/events.json";
import { normalizer } from "../lib/utils";
import { AxiosResponse } from "axios";

// STORE TYPE
export type GlobalState = {
  // login
  session: string;
  sessionStatus: TAPIStatus;
  isInFrame: boolean;

  //transactions
  list: TTransactionResponse[];
  cursor: string;
  cursorArray: string[];
  page: number;
  transactionStatus: TAPIStatus;

  // error: string;
  // loading: boolean;

  // main
  balance: TBalanceResponse;
  balanceStatus: TAPIStatus;
  sportsbook: SportsBook;
  sportsbookStatus: TAPIStatus;
  betslip: TSelection[];
  match: TMatch;
  stake: number;
  detailView: boolean;
  simulateView: boolean;

  // login actions
  clicksLogin: (formData: TLoginRequest) => void;
  clicksLogout: () => void;

  // main actions
  addSelection: (selection: TSelection) => void;
  fetchBalance: () => void;
  updateStake: (newStake: number) => void;
  deleteBetslipItem: (bet: any) => void;
  emptyBetslip: () => void;
  viewMatchDetails: (selection: TMatch) => void;
  toggleDetailView: () => void;
  toggleSimulate: () => void;
  unToggleSimulate: () => void;
  fetchTransactionsList: () => void;
  getPreviousList: () => void;
  getNextList: () => void;
  fetchSportsbook: () => void;
  receiveMsg: (session: string) => void,

  // setLoading: (loading: boolean) => void;
};

// STATE + METHODS
export const useStore = create<GlobalState>(
  devtools((set, get) => ({
    // login
    session: "",
    sessionStatus: "idle", //
    isInFrame: false,

    // transactions
    list: [], // transactionsList
    page: 0, // transcationPage
    cursor: "", //
    cursorArray: [""],
    transactionStatus: "idle",

    // balance
    balance: { balance: 0, error: 0 },
    balanceStatus: "idle",

    // main
    sportsbook: {} as SportsBook,
    sportsbookStatus: "idle",
    betslip: [],
    match: {} as TMatch,
    stake: 100,

    // views
    detailView: false,
    simulateView: false,

    viewMatchDetails: (match: TMatch) =>
      set((state) => ({ ...state, match, detailView: true })),

    toggleDetailView: () =>
      set((state) => ({ ...state, detailView: !state.detailView })),

    updateStake: (newStake: number) =>
      set((state) => ({ ...state, stake: newStake })),

    unToggleSimulate: () => set((state) => ({ ...state, simulateView: false })),

    toggleSimulate: () =>
      set((state) => ({ ...state, simulateView: !state.simulateView })),

    deleteBetslipItem: (selection: TSelection) =>
      set((state) => ({
        ...state,
        betslip: state.betslip.filter(
          (sel) =>
            sel.id !== selection.id ||
            (sel.id === selection.id && sel.value !== selection.value)
        ),
      })),

    emptyBetslip: () => set((state) => ({ ...state, betslip: [] })),

    clicksLogin: async (credentials: TLoginRequest) => {
      set((state) => ({ ...state, sessionStatus: "loading" }));
      try {
        const loginResponse = await httpClient.post("/login", credentials);

        const { session }: TLoginResponse = await loginResponse?.data;
        set((state) => ({
          ...state,
          sessionStatus: "idle",
          session,
        }));

        get().fetchBalance();
        get().fetchSportsbook();
        await navigate("home/sportsbook", { replace: false });
      } catch (e) {
        console.warn("GOT AN ERROR", e);
        set((state) => ({
          ...state,
          sessionStatus: "error",
        }));
      }
    },
    receiveMsg: async (session: string) => {
      set((state) => ({
        ...state,
        session,
        isInFrame: true,
      }));
      try {
        get().fetchBalance();
        get().fetchSportsbook();
        await navigate("home/sportsbook", { replace: false });
      } catch (e) {
        console.warn("GOT AN ERROR", e);
        set((state) => ({
          ...state,
          sessionStatus: "error",
        }));
      }
    },
    clicksLogout: () =>
      set(() => ({
        session: "",
        sportsbook: normalizer(mockdata),
        betslip: [],
        stake: 50,
        match: {} as TMatch,
        detailView: false,
        simulateView: false,
        balance: { balance: 0, error: 0 },
      })),

    fetchBalance: async () => {
      set((state: GlobalState) => ({ ...state, balanceStatus: "loading" }));

      try {
        const { session } = get();
        const balanceResponse = await httpClient.post("/balance", {
          sid: session,
        });
        const balance: TBalanceResponse = await balanceResponse?.data;

        set((state: GlobalState) => ({
          ...state,
          balanceStatus: "idle",
          balance,
        }));
      } catch (e: any) {
        console.warn(JSON.stringify(e.response, null, 3));

        set((state: GlobalState) => ({
          ...state,
          balance: {
            ...state.balance,
            error: e.response.data.error,
            message: e.response.data.message,
          },
          balanceStatus: "error",
        }));
      }
    },

    addSelection: (selection: TSelection) => {
      const isInBetslip = get().betslip.some(
        (s) => s.code === selection.code && s.value === selection.value
      );

      if (!isInBetslip) {
        set((state) => ({
          ...state,
          betslip: [...state.betslip, selection],
        }));
      } else {
        get().deleteBetslipItem(selection);
      }
    },

    fetchTransactionsList: async () => {
      const { cursorArray, page, session } = get();
      const nextcursor = cursorArray[page];

      set((state) => ({
        ...state,
        transactionStatus: "loading",
      }));

      try {
        const response: AxiosResponse = await httpClient.post("/history", {
          sid: session,
          cursor: nextcursor,
        });

        set((state: GlobalState) => ({
          ...state,
          list: response.data.data,
          cursor: response.data.cursor,
          transactionStatus: "idle",
        }));
      } catch (e: any) {
        console.warn(JSON.stringify(e.response, null, 3));
        set((state: GlobalState) => ({
          ...state,
          transactionStatus: "error",
        }));
      }
    },

    getPreviousList: async () => {
      const {
        cursorArray,
        page,
        transactionStatus,
        fetchTransactionsList,
      } = get();

      if (transactionStatus === "loading" || page > 0) {
        cursorArray.pop();

        set((state: GlobalState) => ({
          ...state,
          page: state.page - 1,
          cursorArray,
        }));
        fetchTransactionsList();
      }
    },

    getNextList: async () => {
      const { cursor, transactionStatus, fetchTransactionsList } = get();

      if (transactionStatus === "loading" || cursor) {
        set((state) => ({
          ...state,
          page: state.page + 1,
          cursorArray: [...state.cursorArray, state.cursor],
        }));
        fetchTransactionsList();
      }
    },

    fetchSportsbook: async () => {
      set((state) => ({
        ...state,
        sportsbookStatus: "loading",
      }));

      try {
        const sportsbookResponse = await httpClient.post("/odds", {});

        set((state) => ({
          ...state,
          sportsbook: normalizer(sportsbookResponse.data.data),
          sportsbookStatus: "idle",
        }));
      } catch (e: any) {
        console.log({ e });
        set((state) => ({
          ...state,
          sportsbookStatus: "error",
        }));
      }
    },
  }))
);
