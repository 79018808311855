import React, { useCallback, useEffect, useState } from "react";
import { formatSelection } from "../../lib/utils";
import { useStore } from "../../store/globalstate";
import { TMatch, TOption } from "../../api/types";
import { TSelection } from "../../store/types";
import "./button.scss";

export const OddButton: React.FC<{
  option: TOption;
  market: string;
  match: TMatch;
  outcome: string;
}> = ({ match, market, option, outcome }) => {
  const [active, setActive] = useState(false);

  const addSelection = useStore(useCallback((state) => state.addSelection, []));
  const isSelected = useStore(
    useCallback(
      (state) =>
        state.betslip.some(
          (sel: TSelection) =>
            sel.id === match.id &&
            sel.code === option.code &&
            sel.name === match.odds[market].name
        ),
      [market, match, option]
    )
  );

  useEffect(() => {
    setActive(isSelected);
  }, [isSelected]);

  const handleClick = () => {
    const selection = formatSelection(match, option, market, outcome);
    addSelection(selection);
    setActive(isSelected);
  };

  return (
    <button className={`btn ${active ? "active" : ""}`} onClick={handleClick}>
      {option.value}
    </button>
  );
};
