import React from "react";
import { RouteComponentProps } from "@reach/router";
import { createPostMessage, IPostMessage } from "../../lib/postMessage";
import { useStore } from "../../store/globalstate";
import "./simulateView.scss";

export const SimulateView = (props: RouteComponentProps) => {
  const betslip = useStore((state) => state.betslip);
  const stake = useStore((state) => state.stake);
  const fetchBalance = useStore((state) => state.fetchBalance);
  const toggleSimulate = useStore((s) => s.toggleSimulate);

  const sendDataToSimulate = (createPostMessage: IPostMessage) => {
    const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
      input !== null && input.tagName === "IFRAME";

    const iframe = document.getElementById("simulate");

    if (isIFrame(iframe) && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        JSON.stringify(createPostMessage),
        "https://simulate.cert.novafutur.com/"
      );
    }
  };

  React.useEffect(() => {
    const ll = (e: any) => {
      const key = e.message ? "message" : "data";
      const data = e[key];
      console.log(data);
      if (data === "Simulate has loaded") {
        return sendDataToSimulate(createPostMessage(betslip, stake));
      }
      if (data.type === "refreshBalance") {
        fetchBalance();
      }
    };
    window.addEventListener("message", ll, false);
    return () => {
      window.removeEventListener("message", ll);
    };
  }, [betslip, stake, fetchBalance]);

  return (
    <>
      <div className="iframe-container">
        <iframe
          title="simulate"
          id="simulate"
          src="https://simulate.cert.novafutur.com/"
          height="100%"
          width="100%"
          seamless
          frameBorder="0"
          allowFullScreen
          style={{ position: "absolute", top: 0, left: 0 }}
          sandbox="allow-same-origin allow-scripts"
        >
          ...the browser was unable to load the iframe
        </iframe>
      </div>
      <button
        className="back__btn"
        onClick={() => {
          toggleSimulate();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="small-icon"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 17l-5-5m0 0l5-5m-5 5h12"
          />
        </svg>{" "}
        Go back
      </button>
    </>
  );
};
