import { RouteComponentProps } from "@reach/router";
import { useState } from "react";
import { useStore } from "../../store/globalstate";
import { Spinner } from "../spinner/spinner";
import "./loginPage.scss";

export type Tform = {
  username: string;
  password: string;
};
interface LoginProps extends RouteComponentProps {}
export const LoginPage: React.FC<LoginProps> = () => {
  const [formData, setFormData] = useState<Tform>({
    username: "",
    password: "",
  });

  const sessionStatus = useStore((s) => s.sessionStatus);

  const clicksLogin = useStore((s) => s.clicksLogin);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (sessionStatus !== "loading") {
      clicksLogin(formData);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <dialog className="login" open>
        <h3 className="login__heading">Login</h3>
        <p className="login__heading-info">
          Use demo/demo as login credentials
        </p>
        <form className="form" onSubmit={handleSubmit}>
          <input
            className="form__input"
            type="text"
            name="username"
            placeholder="Username"
            onChange={handleChange}
          />

          <input
            className="form__input"
            type="password"
            name="password"
            placeholder="Password"
            onChange={handleChange}
          />

          <div className="form__message">
            {sessionStatus === "error" && (
              <span className="form__message--error">
                Invalid login credentials
              </span>
            )}
          </div>

          <button className="form__btn" type="submit">
            {sessionStatus === "loading" ? <Spinner /> : <span>Enter </span>}
          </button>
        </form>
      </dialog>
    </div>
  );
};
