import { Redirect, RouteComponentProps } from "@reach/router";
import { useEffect } from "react";
import { useStore } from "../../store/globalstate";

export const NotFoundPage = (props: RouteComponentProps) => {
  const logsOut = useStore((s) => s.clicksLogout);
  useEffect(() => {
    logsOut();
  });
  return <Redirect to="/" noThrow />;
};
