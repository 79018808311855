import { TSelection } from "../store/types";
import { SportsBook, TMatch, TOption } from "../api/types";

export const reduceOdds = (odds: number[]): number => {
  return odds.reduce((curr, next) => curr * next);
};

export const totalOdds = (filteredBets: TSelection[]): number => {
  const oddsArray = filteredBets.map(({ value }) => value);

  if (oddsArray.length > 1) {
    return reduceOdds(oddsArray);
  }

  if (oddsArray.length === 1) {
    return oddsArray[0];
  }

  if (oddsArray === []) {
    return 0.0;
  }

  return 0.0;
};

export const totalWin = (odds: number, stake: number): number => {
  return parseFloat((odds * stake).toFixed());
};

export const getKeyByValue = (object: any, value: any) => {
  const out = Object.keys(object).find((key) => object[key] === value);
  return out ? out : "";
};

export const removeUnderscore = (s: string) => s.replace("_", " ");

export const normalizer = (matches: SportsBook): SportsBook => {
  const sortedOptions = matches.map((match) => {
    return {
      ...match,
      odds: {
        ...match.odds,
        "1": {
          ...match.odds["1"],
          options: {
            " 1": match.odds["1"].options["1"],
            " x": match.odds["1"].options["x"],
            " 2": match.odds["1"].options["2"],
          },
        },
      },
    };
  });

  return sortedOptions;
};

export const formatSelection = (
  match: TMatch,
  option: TOption,
  market: string,
  outcome: string
): TSelection => ({
  date: match.date,
  id: match.id,
  name: match.odds[market].name,
  code: option.code,
  value: option.value,
  outcome: outcome,
  team1: match.team1,
  team2: match.team2,
  market,
});
