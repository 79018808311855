import { useStore } from "../../store/globalstate";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { removeUnderscore } from "../../lib/utils";
import { TMatch, TOdd } from "../../api/types";
import { OddButton } from "../button/button";
import { MarketMap } from "../../lib/MarketMap";
import "./matchDetails.scss";

export const MatchDetails = () => (
  <div className="matchDetails">
    <MatchInfo />
    <MatchList />
  </div>
);

const MatchInfo = () => {
  const { team1, team2 } = useStore((state) => state.match);
  const toggleDetailView = useStore((state) => state.toggleDetailView);
  return (
    <div className="matchDetails__heading">
      <button className="back-btn" onClick={() => toggleDetailView()}>
        Back
      </button>
      <div>
        <span>
          {team1} - {team2}
        </span>
      </div>
    </div>
  );
};

const MatchList = () => {
  const match = useStore((state) => state.match);
  return (
    <div className="scroll-wrapper">
      <ul className="oddsList">
        {Object.keys(match.odds).map((market, i) => {
          // hide handicap markets
          if (parseInt(market) > 43) return <></>;
          return (
            <div key={market + i}>
              <MatchListItem
                market={market}
                odd={match.odds[market]}
                match={match}
              />
            </div>
          );
        })}
      </ul>
    </div>
  );
};

const MatchListItem: React.FC<{
  market: string;
  odd: TOdd;
  match: TMatch;
}> = ({ market, odd, match }) => {
  const { options, name } = odd;
  const [expand, setExpand] = useState<boolean>(false);

  const openClose = () => setExpand(!expand);
  return (
    <li className="matchDetails__item">
      <div className="market-name">
        {removeUnderscore(name)}
        <button onClick={() => openClose()} className="expand-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="info-icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
      <AnimatePresence>
        {expand && (
          <motion.div
            variants={{
              open: { height: "calc(100% - 75px)" },
              closed: { height: "0" },
            }}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{ duration: 0.12 }}
          >
            <div className="market-desc">
              <p>{MarketMap.get(odd.name)?.head}</p>
              <ul>
                {MarketMap.get(odd.name)?.list.map((l: any) => (
                  <li key={l}>{l}</li>
                ))}
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="item-wrapper">
        {Object.keys(options).map((outcome, i) => (
          <div className="outcome-box" key={outcome + i}>
            <div className="outcome">{outcome}</div>
            <OddButton
              market={market}
              option={options[outcome]}
              match={match}
              outcome={outcome}
            />
          </div>
        ))}
      </div>
    </li>
  );
};
