export const SimulateLogo = (props: any) => {
  return (
    <svg width={props?.width} height={props?.heigth} viewBox={props?.viewBox}>
      <title>BrandSimulate</title>
      <path
        d="M12.3438 1L12.1782 1.09333V1.09567L8.44717 7.468L13.0718 15.364L12.2552 16.757L12.2633 16.771H12.247L12.2552 16.757L7.6305 8.861L3 16.771H6.06833L7.6025 14.1507L7.6305 14.1017L7.65967 14.1507L12.2552 22L14.6048 17.9843V17.9832L16.1402 15.3628L11.5178 7.46683L12.3345 6.07383L16.9568 13.9698L17.0642 14.1507L18.5972 16.771H21.6667L12.4745 1.0735L12.3438 1Z"
        fill={props?.fill}
      />
    </svg>
  );
};
