export const MarketMap = new Map();

MarketMap.set("FT_1X2", {
  head: "FT 1X2 is a betting market in which you can wager on one of the following three possible outcomes of a fixture:",
  list: ["1 (the home team wins)", "2 (the away team wins)", "X (a draw)"],
});
MarketMap.set("FT_OU_0.5", {
  head: "FT OU0.5 is a betting market in which you can wager on whether the total number of goals scored by both teams by the end of the game will be over or under the pre-determined spread:",
  list: [
    "Under (no goals to be scored by the end of the game)",
    "Over (one or more goals to be scored by the end of the game)",
  ],
});
MarketMap.set("FT_OU_1.5", {
  head: "FT OU1.5 is a betting market in which you can wager on whether the total number of goals scored by both teams by the end of the game will be over or under the pre-determined spread:",
  list: [
    "Under (at most one goal to be scored by the end of the game)",
    "Over (two or more goals to be scored by the end of the game)",
  ],
});
MarketMap.set("FT_OU_2.5", {
  head: "FT OU2.5 is a betting market in which you can wager on whether the total number of goals scored by both teams by the end of the game will be over or under the pre-determined spread:",
  list: [
    "Under (at most two goals to be scored by the end of the game)",
    "Over (three or more goals to be scored by the end of the game)",
  ],
});
MarketMap.set("FT_OU_3.5", {
  head: "FT OU3.5 is a betting market in which you can wager on whether the total number of goals scored by both teams by the end of the game will be over or under the pre-determined spread:",
  list: [
    "Under (at most three goals to be scored by the end of the game)",
    "Over (four or more goals to be scored by the end of the game)",
  ],
});
MarketMap.set("FT_OU_4.5", {
  head: "FT OU4.5 is a betting market in which you can wager on whether the total number of goals scored by both teams will be over or under the pre-determined spread at the end of the game:",
  list: [
    "Under (at most four goals to be scored by the end of the game)",
    "Over (five or more goals to be scored by the end of the game)",
  ],
});
MarketMap.set("FT_OU_5.5", {
  head: "FT OU5.5 is a betting market in which you can wager on whether the total number of goals scored by both teams by the end of the game will be over or under the pre-determined spread:",
  list: [
    "Under (at most five goals to be scored by the end of the game)",
    "Over (six or more goals to be scored by the end of the game)",
  ],
});
MarketMap.set("FT_OU_6.5", {
  head: "FT OU6.5 is a betting market in which you can wager on whether the total number of goals scored by both teams by the end of the game will be over or under the pre-determined spread:",
  list: [
    "Under (at most six goals to be scored by the end of the game)",
    "Over (seven or more goals to be scored by the end of the game)",
  ],
});
MarketMap.set("FT_OU_7.5", {
  head: "FT OU7.5 is a betting market in which you can wager on whether the total number of goals scored by both teams by the end of the game will be over or under the pre-determined spread:",
  list: [
    "Under (at most seven goals to be scored by the end of the game)",
    "Over (eight or more goals to be scored by the end of the game)",
  ],
});
MarketMap.set("FT_OU_8.5", {
  head: "FT OU8.5 is a betting market in which you can wager on whether the total number of goals scored by both teams by the end of the game will be over or under the pre-determined spread:",
  list: [
    "Under (at most seven goals to be scored by the end of the game)",
    "Over (eight or more goals to be scored by the end of the game)",
  ],
});
MarketMap.set("FT_GG/NG", {
  head: "FT GG/NG is a betting market in which you can wager on whether both teams will score by the end of a game:",
  list: [
    "Goal/Goal (both teams to score by the end of game)",
    "NoGoal (no team to score by the end of the game)",
  ],
});
MarketMap.set("HT_1X2", {
  head: "HT 1X2 is a type of wager where you bet on the outcome of a sporting event at halftime:",
  list: [
    "1 (home team to win at half-time)",
    "2 (away team to win at half-time)",
    "X (draw at half-time)",
  ],
});
MarketMap.set("FT_DC", {
  head: "FT DC is a type of wager where you can bet on two possible outcomes of a game. For example, you could bet that a team will either win or lose, or that the game will end in a draw:",
  list: [
    "1X (fixture is either won by the home team or ends in a draw)",
    "12 (fixture is either won by the home team or the away team)",
    "X2 (fixture is either won by the away team or ends in a draw)",
  ],
});
MarketMap.set("FT_CM", {
  head: "FT ChanceMix is a betting market in which you can wager on either predicting the overall outcome of a fixture or wheter the teams will score:",
  list: [
    "1 or GG (home team to win or both teams to score)",
    "X or GG (the game to end in a draw or both teams to score)",
    "2 or GG (away team to win or both teams to score)",
    "1 or NG (the home team to win or both teams to not score)",
    "X or NG (the game to end in a draw or both teams to not score)",
    "2 or NG (away team to win or both teams to not score)",
  ],
});
MarketMap.set("FT_CM_&_OU_1.5", {
  head: "FT ChanceMix1.5 is a betting market in which you can wager on either predicting the overall outcome of a fixture or that the total no. of goals scored during the game will be over or under a pre-determined spread:",
  list: [
    "1 or over (home team to win or the no. of goals scored during the game to be two or above)",
    "1 or under (home team to win or the no. of goals scored during the game to be at most one)",
    "2 or over (away team to win or the no. of goals scored during the game to be two or above)",
    "2 or under (away team to win or the no. of goals scored during the game to be at most one)",
    "X or over (the fixture to end in a draw or the no. of goals scored during the game to be two or above)",
    "X or under (the fixture to end in a draw or the no. of goals scored during the game to be at most one)",
  ],
});
MarketMap.set("FT_CM_&_OU_2.5", {
  head: "FT ChanceMix2.5 is a betting market in which you can wager on either predicting the overall outcome of a fixture or that the total no. of goals scored during the game will be over or under a pre-determined spread:",
  list: [
    "1 or over (home team to win or the no. of goals scored during the game to be three or above)",
    "1 or under (home team to win or the no. of goals scored during the game to be two or below)",
    "2 or over (away team to win or the no. of goals scored during the game to be three or above)",
    "2 or under (away team to win or the no. of goals scored during the game to be two or below)",
    "X or over (the fixture to end in a draw or the no. of goals scored during the game to be three or above)",
    "X or under (the fixture to end in a draw or the no. of goals scored during the game to be two or below)",
  ],
});
MarketMap.set("FT_CM_&_OU_3.5", {
  head: "FT ChanceMix3.5 is a betting market in which you can wager on either predicting the overall outcome of a fixture or that the total no. of goals scored during the game will be over or under a pre-determined spread:",
  list: [
    "1 or over (home team to win or the no. of goals scored during the game to be four or above)",
    "1 or under (home team to win or the no. of goals scored during the game to be three or below)",
    "2 or over (away team to win or the no. of goals scored during the game to be four or above)",
    "2 or under (away team to win or the no. of goals scored during the game to be three or below)",
    "X or over (the fixture to end in a draw or the no. of goals scored during the game to be four or above)",
    "X or under (the fixture to end in a draw or the no. of goals scored during the game to be three or below)",
  ],
});
MarketMap.set("FT_1X2_&_OU_1.5", {
  head: "FT 1X2 & OU1.5 is a betting market in which you can wager on the overall outcome of a fixture and whether the total number of goals scored by both teams by the end of the game will be over or under the pre-determined spread:",
  list: [
    "1 and under (the home team to win and the total no. of goals scored during the game to be at most one)",
    "1 and over (the home team to win and the total no. of goals scored during the game to be two or above)",
    "X and under (the game to end in a draw the total no. of goals scored during the game to be at most one)",
    "X and over (the game to end in a draw the total no. of goals scored during the game to be two or above)",
    "2 and under (the away team to win and the total no. of goals scored during the game to be at most one)",
    "2 and over (the away team to win and the total no. of goals scored during the game to be two or above)",
  ],
});
MarketMap.set("FT_1X2_&_OU_2.5", {
  head: "",
  list: [
    "1 and under (the home team to win and the total no. of goals scored during the game to be at most two)",
    "1 and over (the home team to win and the total no. of goals scored during the game to be three or above)",
    "X and under (the game to end in a draw the total no. of goals scored during the game to be at most two)",
    "X and over (the game to end in a draw the total no. of goals scored during the game to be three or above)",
    "2 and under (the away team to win and the total no. of goals scored during the game to be at most two)",
    "2 and over (the away team to win and the total no. of goals scored during the game to be three or above)",
  ],
});
MarketMap.set("FT_1X2_&_OU_3.5", {
  head: "",
  list: [
    "1 and under (the home team to win and the total no. of goals scored during the game to be at most three)",
    "1 and over (the home team to win and the total no. of goals scored during the game to be four or above)",
    "X and under (the game to end in a draw the total no. of goals scored during the game to be at most three)",
    "X and over (the game to end in a draw the total no. of goals scored during the game to be four or above)",
    "2 and under (the away team to win and the total no. of goals scored during the game to be at most three)",
    "2 and over (the away team to win and the total no. of goals scored during the game to be four or above)",
  ],
});
MarketMap.set("FT_1X2_&_OU_4.5", {
  head: "FT 1X2 & OU4.5 is a betting market in which you can wager on the overall outcome of a fixture and whether the total number of goals scored by both teams by the end of the game will be over or under the pre-determined spread:",
  list: [
    "1 and under (the home team to win and the total no. of goals scored during the game to be at most four)",
    "1 and over (the home team to win and the total no. of goals scored during the game to be five or above)",
    "X and under (the game to end in a draw the total no. of goals scored during the game to be at most four)",
    "X and over (the game to end in a draw the total no. of goals scored during the game to be five or above)",
    "2 and under (the away team to win and the total no. of goals scored during the game to be at most four)",
    "2 and over (the away team to win and the total no. of goals scored during the game to be five or above)",
  ],
});
MarketMap.set("FT_1X2_&_GGNG", {
  head: "FT 1X2 & GG/NG is a betting market in which you can wager on the overall outcome of a fixture and whether teams will score:",
  list: [
    "1 & GG (the home team to win and both teams to score)",
    "1 & NG (the home team to win and the away team to not score)",
    "2 & GG (the away team to win and both teams to score)",
    "2 & NG (the away team to win and the home team to not score)",
    "X & GG (the game to end in a draw and both teams to score)",
    "X & NG (the game to end in a draw and no team to score)",
  ],
});
MarketMap.set("FT_DC_&_OU_1.5", {
  head: "FT DC&OU1.5 is a type of wager where you can bet on two possible outcomes of a fixture along with wheter if the number of goals scored during the game will be over or under the pre-determined spread:",
  list: [
    "1X Under (home team to win or the match to end in a draw and at most one goal to be scored during the game)",
    "X2 Under (away team to win or the match to end in a draw and at most one goal to be scored during the game)",
    "1X Over (home team to win or the match to end in a draw and at least two goals to be scored during the game)",
    "X2 Over (away team to win or the match to end in a draw and at least two goals to be scored during the game)",
    "12 Under (home team to win or the away team to win and at most one goal to be scored during the game)",
    "12 Over (home team to win or the away team to win and at least two goals to be scored during the game)",
  ],
});
MarketMap.set("FT_DC_&_OU_2.5", {
  head: "FT DC&OU2.5 is a type of wager where you can bet on two possible outcomes of a fixture along with wheter if the number of goals scored during the game will be over or under the pre-determined spread:",
  list: [
    "1X Under (home team to win or the match to end in a draw and at most two goals to be scored during the game)",
    "X2 Under (away team to win or the match to end in a draw and at most two goals to be scored during the game)",
    "1X Over (home team to win or the match to end in a draw and at least three goals to be scored during the game)",
    "X2 Over (away team to win or the match to end in a draw and at least three goals to be scored during the game)",
    "12 Under (home team to win or the away team to win and at most two goals to be scored during the game)",
    "12 Over (home team to win or the away team to win and at least three goals to be scored during the game)",
  ],
});
MarketMap.set("FT_DC_&_OU_3.5", {
  head: "FT DC&OU3.5 is a type of wager where you can bet on two possible outcomes of a fixture along with wheter if the number of goals scored during the game will be over or under the pre-determined spread:",
  list: [
    "1X Under (home team to win or the match to end in a draw and at most three goals to be scored during the game)",
    "X2 Under (away team to win or the match to end in a draw and at most three goals to be scored during the game)",
    "1X Over (home team to win or the match to end in a draw and at least four goals to be scored during the game)",
    "X2 Over (away team to win or the match to end in a draw and at least four goals to be scored during the game)",
    "12 Under (home team to win or the away team to win and at most three goals to be scored during the game)",
    "12 Over (home team to win or the away team to win and at least four goals to be scored during the game)",
  ],
});
MarketMap.set("FT_DC_&_OU_4.5", {
  head: "FT DC&OU4.5 is a type of wager where you can bet on two possible outcomes of a fixture along with wheter if the number of goals scored during the game will be over or under the pre-determined spread:",
  list: [
    "1X Under (home team to win or the match to end in a draw and at most four goals to be scored during the game)",
    "X2 Under (away team to win or the match to end in a draw and at most four goals to be scored during the game)",
    "1X Over (home team to win or the match to end in a draw and at least five goals to be scored during the game)",
    "X2 Over (away team to win or the match to end in a draw and at least five goals to be scored during the game)",
    "12 Under (home team to win or the away team to win and at most four goals to be scored during the game)",
    "12 Over (home team to win or the away team to win and at least five goals to be scored during the game)",
  ],
});
MarketMap.set("FT_CS", {
  head: "FT Correct Score is a type of wager where you can bet on the predicting the exact score of a game at the end of the fixture:",
  list: [
    "1-0",
    "2-0",
    "2-1",
    "3-0",
    "3-1",
    "3-2",
    "4-0",
    "4-1",
    "4-2",
    "4-3",
    "0-0",
    "1-1",
    "2-2",
    "3-3",
    "4-4",
    "0-1",
    "0-2",
    "0-3",
    "0-4",
    "1-2",
    "1-3",
    "1-4",
    "2-3",
    "2-4",
    "3-4",
    "Any other score (any other score not covered in the list of correct scores)",
  ],
});
MarketMap.set("FT_CS_M", {
  head: "FT MultiC.Score(4) is a type of wager where you can bet on predicting the correct score of a fixture using a set of pre-defined lists:",
  list: [
    "0-0/1-1/0-1/1-0 (the final score is in this list)",
    "2-0/2-1/3-0/3-1 (the final score is in this list)",
    "0-2/1-2/0-3/1-3 (the final score is in this list)",
    "4-0/4-1/4-2/4-3 (the final score is in this list)",
    "0-4/1-4/2-4/3-4 (the final score is in this list)",
    "4-4/other (the final score is in this list or can't be found in any of the lists)",
  ],
});
MarketMap.set("FT_CS_M2", {
  head: "FT MultiC.Score(3) is a type of wager where you can bet on predicting the correct score of a fixture using a pre-defined list of correct scores:",
  list: [
    "0-0/1-1 (the final score is in this list)",
    "2-2/3-3/4-4 (the final score is in this list)",
    "1-0/2-0/2-1 (the final score is in this list)",
    "0-1/0-2/1-2 (the final score is in this list)",
    "3-0/3-1/3-2 (the final score is in this list)",
    "0-3/1-3/2-3 (the final score is in this list)",
    "4-1/4-2/4-03 (the final score is in this list)",
    "1-4/2-4/3-4 (the final score is in this list)",
    "4-0/0-4/other (the final score is in this list or can't be found in any of the lists)",
  ],
});
MarketMap.set("FT_MG", {
  head: "FT Multi-Goal is a type of wager where you can bet on predicting the total no. of goals scored during the game by choosing from a set of pre-defined inclusive ranges:",
  list: [
    "1-2 goals (the total no. of goals scored by the end of the game is either one or two)",
    "1-3 goals (the total no. of goals scored by the end of the game is between one and three)",
    "1-4 goals (the total no. of goals scored by the end of the game is between one and four)",
    "1-5 goals (the total no. of goals scored by the end of the game is between one and five)",
    "1-6 goals (the total no. of goals scored by the end of the game is between one and six)",
    "2-3 goals (the total no. of goals scored by the end of the game is either two or three)",
    "2-4 goals (the total no. of goals scored by the end of the game is between two and four)",
    "2-5 (the total no. of goals scored by the end of the game is between two and five)",
    "2-6 (the total no. of goals scored by the end of the game is between two and six)",
    "3-4 (the total no. of goals scored by the end of the game is between three and four)",
    "3-5 (the total no. of goals scored by the end of the game is between three and five)",
    "3-6 (the total no. of goals scored by the end of the game is between three and six)",
    "4-5 (the total no. of goals scored by the end of the game is either four or five)",
    "4-6 (the total no. of goals scored by the end of the game is between four and six)",
    "5-6 (the total no. of goals scored by the end of the game is either five or six)",
  ],
});
MarketMap.set("FT_HA_&_OU_0.5", {
  head: "FT H/A OU0.5 is a type of wager where you can bet on whether the number of goals scored by the home team or the away team will be over or under the pre-determined spread:",
  list: [
    "Home Over (home team to score at least one goal by the end of the game)",
    "Home Under (home team to not score any goals by the end of the game)",
    "Away Over (away team to score at least one goal by the end of the game)",
    "Away Under (away team to not score any goals by the end of the game)",
  ],
});
MarketMap.set("FT_HA_&_OU_1.5", {
  head: "FT H/A OU1.5 is a type of wager where you can bet on whether the number of goals scored by the home team or the away team will be over or under the pre-determined spread:",
  list: [
    "Home Over (home team to score at least two goals by the end of the game)",
    "Home Under (home team to score at most one goal by the end of the game)",
    "Away Over (away team to score at least two goals by the end of the game)",
    "Away Under (away team to score at most one goal by the end of the game)",
  ],
});
MarketMap.set("FT_HA_&_OU_2.5", {
  head: "H/A OU2.5 is a type of wager where you can bet on whether the number of goals scored by the home team or the away team will be over or under the pre-determined spread:",
  list: [
    "Home Over (home team to score at least three goals by the end of the game)",
    "Home Under (home team to score at most two goals by the end of the game)",
    "Away Over (away team to score at least three goals by the end of the game)",
    "Away Under (away team to score at most two goals by the end of the game)",
  ],
});
MarketMap.set("FT_HA_&_OU_3.5", {
  head: "FT H/A OU3.5 is a type of wager where you can bet on whether the number of goals scored by the home team or the away team will be over or under the pre-determined spread:",
  list: [
    "Home Over (home team to score at least four goals by the end of the game)",
    "Home Under (home team to score at most three goals by the end of the game)",
    "Away Over (away team to score at least four goals by the end of the game)",
    "Away Under (away team to score at most three goals by the end of the game)",
  ],
});
MarketMap.set("HT_OU_0.5", {
  head: "HTOU0.5 is a betting market in which you can wager on predicting the no. of goals scored will be over or under a pre-determined spread at half-time:",
  list: [
    "under (no goals to be scored by half-time)",
    "over (one or more goals to be scored by half-time)",
  ],
});
MarketMap.set("HT_OU_1.5", {
  head: "HTOU1.5 is a betting market in which you can wager on predicting the no. of goals scored will be over or under a pre-determined spread at half-time:",
  list: [
    "under (at most one goal to be scored by half-time)",
    "over (two or more goals to be scored by half-time)",
  ],
});
MarketMap.set("HT_OU_2.5", {
  head: "HTOU2.5 is a betting market in which you can wager on predicting the no. of goals scored will be over or under a pre-determined spread at half-time:",
  list: [
    "under (at most two goals to be scored by half-time)",
    "over (three or more goals to be scored by half-time)",
  ],
});
MarketMap.set("HT_DC", {
  head: "HT DC is a type of wager where you can bet on two possible outcomes of a game at half-time:",
  list: [
    "1X (home team to win or draw at half-time)",
    "12 (home team to win or away team to win at half-time)",
    "X2 (away team to win or draw at half-time)",
  ],
});
MarketMap.set("HT_GG/NG", {
  head: "HT GG/NG is a betting market in which you can wager on whether both teams will score by half-time:",
  list: [
    "GG (both teams to score by half-time)",
    "NG (no team to score by half-time)",
  ],
});
MarketMap.set("FT_ODD/EVEN", {
  head: "FT ODD/EVEN is a type of wager where you can wager on predicting if the total no. of goals scored during the game is an odd or even number:",
  list: [
    "odd (the total no. of goals scored during the game is an odd number)",
    "even (the total no. of goals scored during the game is an even number)",
  ],
});
MarketMap.set("HT_FT", {
  head: "HT FT is a type of wager where you can wager on predicting the outcome of a game at half-time and full-time:",
  list: [
    "1/1 (the home team to win at half-time and full-time)",
    "1/X (the home team to win at half-time and the match to end in a draw)",
    "1/2 (the home team to win at half-time and the away team to win at full-time)",
    "X/1 (draw at half-time and the home team to win at full-time)",
    "X/X (draw at half-time and draw at full-time)",
    "X/2 (draw at half-time and away team to win at full-time)",
    "2/1 (away team to win at half-time and home team to win at full-time)",
    "2/X (away team to win at half-time and the game to end up in a draw)",
    "2/2 (away team to win at half-time and at full-time)",
  ],
});
MarketMap.set("HT_ODD/EVEN", {
  head: "HT ODD/EVEN is a type of wager where you can wager on predicting if the total no. of goals scored by half-time is an odd or even number:",
  list: [
    "odd (the total no. of goals scored during by half-time is an odd number)",
    "even (the total no. of goals scored during by half-time is an even number)",
  ],
});
