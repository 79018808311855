import { RouteComponentProps } from "@reach/router";
import { useStore } from "../../store/globalstate";
import { Betslip } from "../betslip/betslip";
import { SimulateView } from "../simulateView/simulateView";
import { Sportsbook } from "../sportsBook/sportsbook";
import "./mainView.scss";

export const MainView = (props: RouteComponentProps) => {
  const simulateView = useStore((state) => state.simulateView);

  if (simulateView) {
    return <SimulateView />;
  }

  return (
    <div className="mainView">
      <Sportsbook />
      <Betslip />
    </div>
  );
};
