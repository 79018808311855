import { Router } from "@reach/router";
import { LoginPage } from "./components/loginPage/loginPage";
import { HomePage } from "./components/homePage/homePage";
import { TransactionsView } from "./components/transactionsView/transactionsView";
import { MainView } from "./components/mainView/mainView";
import { NotFoundPage } from "./components/notFoundPage/notFoundPage";
import { ReceiveMsgPage } from "./components/receiveMsgPage/receiveMsgPage";


export const App = () => (
  <Router>
    <ReceiveMsgPage path="/msg" />
    <LoginPage path="/" />
    <HomePage path="/home/">
      <MainView path="sportsbook" />
      <TransactionsView path="transactions" />
    </HomePage>
    <NotFoundPage default />
  </Router>
);
