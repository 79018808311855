import { RouteComponentProps, Link, useLocation } from "@reach/router";
import { useStore } from "../../store/globalstate";
import { SimulateLogo } from "../svg";
import "./header.scss";

interface HeaderProps extends RouteComponentProps {}
export const Header: React.FC<HeaderProps> = () => {
  const { pathname } = useLocation();
  return (
    <nav className="nav">
      <Links />
      <div>
        <Balance />
        {!pathname.includes("transactions") && <SimulateToggle />}
      </div>
    </nav>
  );
};

const Links = () => {
  const clicksLogout = useStore((s) => s.clicksLogout);
  const toggleSimulate = useStore((s) => s.unToggleSimulate);
  const isInFrame = useStore((s) => s.isInFrame);
  const { pathname } = useLocation();
  return (
    <div className="nav__link-container">
      <span className="nav__logo">
        <SimulateLogo
          width="24"
          height="24"
          viewBox="0 0 22 22"
          fill="#F6B52D"
        />
      </span>
      {pathname.includes("sportsbook") ? (
        <Link
          className="nav__link"
          to="transactions"
          onClick={() => {
            toggleSimulate();
          }}
        >
          Transactions
        </Link>
      ) : (
        <Link className="nav__link" to="sportsbook">
          Sportsbook
        </Link>
      )}{" "}
      {!isInFrame &&
        <Link className="nav__link" to="/" onClick={clicksLogout}>
          Logout
        </Link>
      }
    </div>
  );
};

const Balance = () => {
  const balance = useStore((state) => state.balance);
  const balanceStatus = useStore((state) => state.balanceStatus);

  if (balanceStatus === "error") {
    return (
      <span>
        error: {balance.error} - {balance.message}
      </span>
    );
  }

  return (
    <div className="nav__balance">
      <span className="title">Balance:</span>{" "}
      <span className="value">{balance.balance}</span>
    </div>
  );
};

const SimulateToggle = () => {
  const toggleSimulate = useStore((s) => s.toggleSimulate);
  const simulateView = useStore((s) => s.simulateView);
  const fetchBalance = useStore((s) => s.fetchBalance);
  return (
    <div className="sim-toggle-container">
      <input
        type="checkbox"
        name="simulate"
        checked={simulateView}
        onChange={() => {
          if (simulateView) {
            fetchBalance();
          }
          toggleSimulate();
        }}
      />
      <div className="sim-toggle-slider"></div>
      <div className="sim-display">
        <div className="sim-real">REAL</div>
        <svg viewBox="-4 -1 79 8" className="sim-logo">
          <path d="M11.142.247V6.84H9.627V.247h1.515zm11.56 0v6.592h-1.524V1.753h-2.074v5.086h-1.515V1.753h-1.683c-.11 0-.204.039-.283.117-.078.079-.118.172-.118.28v4.69h-1.524V2.16c0-.53.188-.982.564-1.352.373-.374.826-.561 1.36-.561h6.798zm3.724 0v5.091h3.953c.121 0 .217-.033.287-.1.079-.078.118-.164.118-.257V.247H32.3v4.634c0 .546-.186 1.004-.56 1.375-.388.389-.841.583-1.36.583h-5.468V.247h1.515zm9.596 0v4.734c0 .093.04.18.119.258.066.066.162.099.286.099h4.81v1.501h-4.81c-.516 0-.97-.194-1.36-.583-.373-.37-.56-.829-.56-1.375V.247h1.515zm14.492 0V6.84H49V5.166h-4.04V6.84h-1.515V2.205c0-.546.187-1.004.56-1.375.388-.388.841-.583 1.36-.583h5.15zm9.315 0v1.506H57.08v5.086h-1.515V1.753h-2.844V.247h7.107zm8.937 0v1.506h-5.214v1.035h5.214v1.51h-5.214v.683c0 .093.04.18.118.258.067.066.162.1.287.1h4.809v1.5h-4.81c-.515 0-.968-.194-1.36-.583-.373-.37-.56-.829-.56-1.375V.247h6.73zM7.01.247v1.506H1.897c-.124 0-.232.045-.323.135-.1.103-.15.202-.15.299v.646h5.587L7.015 4.9c0 .548-.183 1.005-.55 1.37-.382.38-.831.57-1.347.57H.005V5.333h4.931c.125 0 .232-.046.323-.136.082-.084.13-.167.146-.249v-.7H0V2.186c0-.549.183-1.005.55-1.37.383-.38.832-.57 1.347-.57h5.114zm41.988 1.501h-3.635c-.121 0-.217.033-.286.1-.08.078-.119.164-.119.257v1.556H49V1.748z" />
        </svg>
      </div>
    </div>
  );
};
